//paths
$fonts-folder: "../../../fonts";
$images-folder: '../img/';

//fonts
$font-secondary: "FuturaPTLight", serif;
$font-primary: "FuturaPTBook", serif;
$font-tertiary: "FuturaPTDemi", serif;

//color usage
$color-primary: #2a1552;
$color-secondary: #805193;
$gradient-primary: linear-gradient(#291b44, #18142a);
$gradient-secondary: linear-gradient(to bottom, #18142a, #291b44);

//Descriptive Base Colors
$color-text: #191919;




//container
$cont-width: 1440px;
$cont-padding: 60px;
