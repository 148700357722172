.top-footer {
    background-color: #eae9f6;
    padding: 60px 0 20px;

    .top-footer-cols {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        >div:not(:last-child) {
            padding-right: 100px;
            padding-bottom: 25px;
        }
    }

    h5 {
        text-transform: uppercase;
    }

    ul {
        li {
            a {
                transition: all .3s;
                &:hover {
                    color: darken($color-primary, 50%);
                }
            }
        }
    }
}

.bottom-footer {
    background-color: #191919;
    padding: 5px 20px;
}
