input[type="text"], textarea {
    border: 1px solid rgba(255, 255, 255, .5);
    background-color: transparent;
    color: rgb(255, 255, 255);


    width: 100%;
    transition: all .3s;

    &:focus {
        border: 1px solid rgb(255, 255, 255);
    }
}
input[type="text"] {
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    border-radius: 20px;
}

textarea {
    resize: none;
    padding: 30px;
    border-radius: 30px;
}

