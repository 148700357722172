html {
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
}

body {
    color: $color-primary;
    font-size: 16px;
    font-family: $font-primary;
    line-height: 1.5;
    outline: none;
    word-wrap: break-word;
    font-weight: 400;
    overflow-x: hidden;
}

*, *:before, *:after {
    outline: none;
    box-sizing: inherit;
}

.container {
    max-width: ($cont-width + $cont-padding);
    width: 100%;
    padding-left: ($cont-padding / 2);
    padding-right: ($cont-padding / 2);
    margin-left: auto;
    margin-right: auto;
}
