.uppercase {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.color-text {
    color: $color-text;
}

.color-white {
    color: #fff;
}

.color-primary {
    color: $color-primary;
}

.color-secondary {
    color: $color-secondary;
}
