header {
    padding: 13px 0;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fff;
    border-bottom: 1px solid transparent;
    transition: all 0.2s;
    z-index: 1;

    &.sticky {
        z-index: 10;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-bottom-color: #e6e6e6;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.header-menu {
    ul {
        display: flex;

        li {
            margin-left: 40px;
            text-transform: uppercase;

            a {
                position: relative;
                display: block;

                &:after {
                    content: "";
                    position: absolute;
                    height: 3px;
                    width: 0;
                    background-image: $gradient-primary;
                    bottom: -2px;
                    left: 0;
                    transition: width .3s;

                }
            }

            &.active {
                a {
                    &:after {
                        width: 80%;
                    }
                }
            }
        }
    }
}
.logo {
    transition: all 0.3s;

    &:hover {
        opacity: 0.8;
    }
}
