.burger {
    width: 60px;
    height: 45px;
    position: relative;
    margin: 50px auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    zoom: .45;
    display: none;
}

.burger span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: $color-secondary;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}




/* Icon 4 */



.burger span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
}

.burger span:nth-child(2) {
    top: 18px;
    transform-origin: left center;
}

.burger span:nth-child(3) {
    top: 36px;
    transform-origin: left center;
}

.burger.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

.burger.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.burger.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
}
