.btn {
    border: 1px solid $color-secondary;
    text-align: center;
    text-transform: uppercase;
    transition: all .3s;
    cursor: pointer;



    &.with-img {
        img {
            width: 16px;
            margin-left: 10px;
            margin-bottom: 3px;

        }
    }

    &.btn-lg {
        font-size: 34px;
        font-family: $font-primary;
        padding: 10px;
        width: 300px;
        border-radius: 50px;
    }

    &.btn-sm {
        font-size: 16px;
        font-family: $font-primary;
        padding: 10px;
        width: 130px;
        border-radius: 25px;
    }

    &.primary {
        background-color: $color-secondary;
        color: #fff;

        &:hover {
            background-color: darken($color-secondary, 10%);
        }
    }

    &.outline {
        background-color: transparent;
        color: $color-secondary;

        &:hover {
            color: darken($color-secondary, 10%);
            border-color: darken($color-secondary, 10%);
        }
    }
}
.no-style {
    border: 0;
    padding: 0;
    background-color: transparent;
    outline: none;

    &:focus {
        border: none;
    }
}

.scroll-to-top {

    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 24px;
    right: 30px;
    bottom: 40px;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 7px 20px 0px rgba(0,0,0,0.2);
    transition: all .3s;
    border: none;
    background: #fff;
    color: $color-primary;
    text-align: center;

    &:hover {
        color: #fff;
        background: $color-primary;
    }


    &.show {
        opacity: 1;
        visibility: visible;
    }
}
