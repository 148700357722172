h1 {
    font-size: 72px;
    font-family: $font-tertiary;
}

h2 {
    font-size: 52px;
}

h3 {
    font-size: 30px;
}

