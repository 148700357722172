@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@font-face {
  font-family: "FuturaPTLight";
  font-style: normal;
  font-weight: 400;
  src: url("../../../fonts/FuturaPTLight/FuturaPTLight.eot");
  src: url("../../../fonts/FuturaPTLight/FuturaPTLight.eot?#iefix") format("embedded-opentype"), url("../../../fonts/FuturaPTLight/FuturaPTLight.woff") format("woff"), url("../../../fonts/FuturaPTLight/FuturaPTLight.ttf") format("truetype"), url("../../../fonts/FuturaPTLight/FuturaPTLight.svg") format("svg");
}
@font-face {
  font-family: "FuturaPTBook";
  font-style: normal;
  font-weight: 400;
  src: url("../../../fonts/FuturaPTBook/FuturaPTBook.eot");
  src: url("../../../fonts/FuturaPTBook/FuturaPTBook.eot?#iefix") format("embedded-opentype"), url("../../../fonts/FuturaPTBook/FuturaPTBook.woff") format("woff"), url("../../../fonts/FuturaPTBook/FuturaPTBook.ttf") format("truetype"), url("../../../fonts/FuturaPTBook/FuturaPTBook.svg") format("svg");
}
@font-face {
  font-family: "FuturaPTDemi";
  font-style: normal;
  font-weight: 400;
  src: url("../../../fonts/FuturaPTDemi/FuturaPTDemi.eot");
  src: url("../../../fonts/FuturaPTDemi/FuturaPTDemi.eot?#iefix") format("embedded-opentype"), url("../../../fonts/FuturaPTDemi/FuturaPTDemi.woff") format("woff"), url("../../../fonts/FuturaPTDemi/FuturaPTDemi.ttf") format("truetype"), url("../../../fonts/FuturaPTDemi/FuturaPTDemi.svg") format("svg");
}
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article, aside, details, figcaption, figure, dialog,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

a {
  text-decoration: none;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
}

body {
  color: #2a1552;
  font-size: 16px;
  font-family: "FuturaPTBook", serif;
  line-height: 1.5;
  outline: none;
  word-wrap: break-word;
  font-weight: 400;
  overflow-x: hidden;
}

*, *:before, *:after {
  outline: none;
  box-sizing: inherit;
}

.container {
  max-width: 1500px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 72px;
  font-family: "FuturaPTDemi", serif;
}

h2 {
  font-size: 52px;
}

h3 {
  font-size: 30px;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.color-text {
  color: #191919;
}

.color-white {
  color: #fff;
}

.color-primary {
  color: #2a1552;
}

.color-secondary {
  color: #805193;
}

.btn {
  border: 1px solid #805193;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s;
  cursor: pointer;
}
.btn.with-img img {
  width: 16px;
  margin-left: 10px;
  margin-bottom: 3px;
}
.btn.btn-lg {
  font-size: 34px;
  font-family: "FuturaPTBook", serif;
  padding: 10px;
  width: 300px;
  border-radius: 50px;
}
.btn.btn-sm {
  font-size: 16px;
  font-family: "FuturaPTBook", serif;
  padding: 10px;
  width: 130px;
  border-radius: 25px;
}
.btn.primary {
  background-color: #805193;
  color: #fff;
}
.btn.primary:hover {
  background-color: #633f72;
}
.btn.outline {
  background-color: transparent;
  color: #805193;
}
.btn.outline:hover {
  color: #633f72;
  border-color: #633f72;
}

.no-style {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: none;
}
.no-style:focus {
  border: none;
}

.scroll-to-top {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 24px;
  right: 30px;
  bottom: 40px;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  border: none;
  background: #fff;
  color: #2a1552;
  text-align: center;
}
.scroll-to-top:hover {
  color: #fff;
  background: #2a1552;
}
.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
}

input[type=text], textarea {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  color: white;
  width: 100%;
  transition: all 0.3s;
}
input[type=text]:focus, textarea:focus {
  border: 1px solid white;
}

input[type=text] {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  border-radius: 20px;
}

textarea {
  resize: none;
  padding: 30px;
  border-radius: 30px;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-0 {
  margin: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-0 {
  padding: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-5 {
  margin: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-5 {
  padding: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-10 {
  margin: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-10 {
  padding: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-15 {
  margin: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-15 {
  padding: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-20 {
  margin: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-20 {
  padding: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.m-25 {
  margin: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.p-25 {
  padding: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-30 {
  margin: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-30 {
  padding: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.m-35 {
  margin: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.p-35 {
  padding: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-40 {
  margin: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-40 {
  padding: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.m-45 {
  margin: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.p-45 {
  padding: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-50 {
  margin: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-50 {
  padding: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.my-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mx-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.m-55 {
  margin: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.py-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.px-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.p-55 {
  padding: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mx-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-60 {
  margin: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-60 {
  padding: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.my-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mx-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.m-65 {
  margin: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.px-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.p-65 {
  padding: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.my-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mx-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-70 {
  margin: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.px-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-70 {
  padding: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.my-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mx-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.m-75 {
  margin: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.py-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.px-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.p-75 {
  padding: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-80 {
  margin: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-80 {
  padding: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.my-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mx-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.m-85 {
  margin: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.py-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.px-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.p-85 {
  padding: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.my-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mx-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-90 {
  margin: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.px-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-90 {
  padding: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.my-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mx-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.m-95 {
  margin: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.py-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.px-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.p-95 {
  padding: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-100 {
  margin: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-100 {
  padding: 100px;
}

.cls-1 {
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.cls-1:hover {
  opacity: 0.3;
}

.cls-2 {
  fill: #7bc0c9;
}

.cls-3 {
  fill: url(#linear-gradient);
}

.cls-4 {
  fill: url(#linear-gradient-2);
}

.cls-5 {
  fill: url(#linear-gradient-3);
}

.cls-6 {
  fill: url(#linear-gradient-4);
}

.cls-7 {
  fill: url(#linear-gradient-5);
}

.cls-8 {
  fill: url(#linear-gradient-6);
}

.cls-9 {
  fill: #743e83;
}

.slick-slide {
  padding: 0 10px;
}
.slick-slide img {
  width: 100%;
}

.slick-track {
  padding: 40px 0px;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #805193;
}
.slick-dots li.slick-active button:before {
  color: #805193;
}

.burger {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  zoom: 0.45;
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #805193;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

/* Icon 4 */
.burger span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-child(2) {
  top: 18px;
  transform-origin: left center;
}

.burger span:nth-child(3) {
  top: 36px;
  transform-origin: left center;
}

.burger.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

.burger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.burger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}

header {
  padding: 13px 0;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  z-index: 1;
}
header.sticky {
  z-index: 10;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-bottom-color: #e6e6e6;
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-menu ul {
  display: flex;
}
.header-menu ul li {
  margin-left: 40px;
  text-transform: uppercase;
}
.header-menu ul li a {
  position: relative;
  display: block;
}
.header-menu ul li a:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  background-image: linear-gradient(#291b44, #18142a);
  bottom: -2px;
  left: 0;
  transition: width 0.3s;
}
.header-menu ul li.active a:after {
  width: 80%;
}

.logo {
  transition: all 0.3s;
}
.logo:hover {
  opacity: 0.8;
}

.top-footer {
  background-color: #eae9f6;
  padding: 60px 0 20px;
}
.top-footer .top-footer-cols {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.top-footer .top-footer-cols > div:not(:last-child) {
  padding-right: 100px;
  padding-bottom: 25px;
}
.top-footer h5 {
  text-transform: uppercase;
}
.top-footer ul li a {
  transition: all 0.3s;
}
.top-footer ul li a:hover {
  color: black;
}

.bottom-footer {
  background-color: #191919;
  padding: 5px 20px;
}

.banner {
  background: url("../../../img/world-map.png") no-repeat center 90px;
  display: flex;
  align-items: center;
  padding-top: 90px;
}

.home-banner {
  min-height: 840px;
}
.home-banner .content {
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}
.home-banner h1 {
  margin-bottom: 150px;
}
.home-banner p {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  font-size: 24px;
  color: #492392;
}

.services {
  background-image: linear-gradient(#291b44, #18142a);
  padding: 100px 0;
  position: relative;
}
.services .service-btn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.services .service-card {
  display: block;
  cursor: pointer;
  width: 90%;
  height: 0;
  padding-bottom: 66.67%;
  position: relative;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}
.services .service-card:hover {
  transform: scale(1.06);
  z-index: 1;
  box-shadow: 0px 0px 74px 2px rgba(0, 0, 0, 0.2);
}
.services .service-card img {
  position: absolute;
  border-radius: 6px;
}
.services .service-card p {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 0 10px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}

.about-company {
  padding: 180px 0;
  background: url("../../../img/zenon-bg.png") no-repeat right top;
  background-size: 80%;
}

.text-with-img {
  margin-top: 30px;
  position: relative;
}
.text-with-img .section-img {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 80%;
  height: 545px;
  background: no-repeat center;
  background-size: cover;
}
.text-with-img .section-text {
  padding-top: 100px;
  max-width: 600px;
  width: 100%;
}
.text-with-img .section-text .section-text-content {
  padding: 70px 50px;
  background-color: #f1fbff;
}
.text-with-img.img-right .section-text {
  margin-right: auto;
}
.text-with-img.img-right .section-img {
  right: 0;
}
.text-with-img.img-left .section-text {
  margin-left: auto;
}
.text-with-img.img-left .section-img {
  left: 0;
}

.quality {
  padding-bottom: 100px;
}

.partners p {
  width: 600px;
  margin: 0 auto;
}

.partners-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.partners-logos img {
  margin: 0 25px 15px;
}

.contact-title {
  background-color: #eae9f6;
  padding: 50px 0;
}
.contact-title p {
  width: 600px;
  margin: 0 auto;
}

.contact-social {
  background-image: linear-gradient(#291b44, #18142a);
  padding: 40px;
}
.contact-social ul {
  display: flex;
  justify-content: center;
  color: #fff;
}
.contact-social ul li {
  margin: 0 50px;
  display: flex;
  align-items: center;
}
.contact-social ul li img {
  margin-right: 20px;
}

.contact-form {
  background-image: linear-gradient(#291b44, #18142a);
  padding: 80px 0;
}
.contact-form form {
  max-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
}

.form-inputs {
  display: flex;
  justify-content: space-between;
}
.form-inputs > div {
  width: 48%;
}

.services-banner {
  min-height: 930px;
  align-items: flex-start;
}
.services-banner .services-img {
  position: absolute;
  top: 50px;
  left: 0;
}
.services-banner h1 {
  max-width: 600px;
  width: 100%;
  padding-top: 25px;
  text-align: left;
  margin: 0 auto;
}
.services-banner .content {
  width: 1260px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  position: relative;
}

.other-services {
  padding: 100px 0;
  background: url("../../../img/other-services.jpg") no-repeat center center;
  background-size: cover;
  color: #fff;
}
.other-services h3 {
  font-family: "FuturaPTLight", serif;
  text-transform: uppercase;
  padding-bottom: 50px;
}
.other-services .content {
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
.other-services ul, .other-services li {
  list-style: disc;
}

.service-section .img-right .section-top {
  margin-left: auto;
}
.service-section .img-right .section-top .section-top-text h3 {
  padding-right: 52px;
}
.service-section .img-left .section-top {
  margin-right: auto;
}
.service-section .img-left .section-top .section-top-text {
  flex-direction: row-reverse;
}
.service-section .img-left .section-top .section-top-text h3 {
  padding-left: 52px;
  text-align: right;
}
.service-section .section-top {
  width: 80%;
}
.service-section .section-top .section-img {
  top: 100px;
}
.service-section .section-top .section-top-text {
  display: flex;
}
.service-section .section-top .section-top-text .gradient-bg {
  height: 100px;
  width: 50%;
  background: linear-gradient(to bottom, #18142a, #291b44);
}
.service-section .section-top .section-top-text h3 {
  width: 50%;
  text-transform: uppercase;
}

@media (max-width: 1300px) and (min-width: 1024px) {
  .section-top-text h3 {
    font-size: 24px;
  }
}
@media (max-width: 787px) {
  .header-menu ul li a:after {
    display: none;
  }
}
@media (max-width: 1023px) {
  /*globa;*/
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 20px;
  }

  .services-banner {
    min-height: 500px;
  }

  .header-menu ul li {
    margin-left: 20px;
  }

  .banner {
    background-size: contain;
  }

  .home-banner {
    min-height: 650px;
  }
  .home-banner p {
    font-size: 14px;
  }

  .services-banner {
    min-height: 680px;
  }
  .services-banner .services-img {
    left: 50%;
    transform: translateX(-50%);
    width: 700px;
  }
  .services-banner h1 {
    max-width: 450px;
  }

  .service-section.py-80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .service-section .text-with-img .section-img {
    height: 350px;
  }
  .service-section .text-with-img .section-text {
    max-width: 490px;
    width: 100%;
  }
  .service-section .text-with-img .section-text .section-text-content {
    padding: 25px;
  }

  .services {
    padding: 50px 0;
  }

  .partners-logos {
    padding-top: 0;
  }
  .partners-logos img {
    width: 90px;
  }

  .contact-social ul li {
    margin: 0 20px;
  }
  .contact-social ul li img {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 23px;
  }

  h3 {
    font-size: 14px;
  }

  .btn.btn-lg {
    font-size: 24px;
    width: 200px;
    border-radius: 50px;
  }

  .services-banner h1 {
    text-align: center;
  }

  .service-section .section-top {
    width: 100%;
  }
  .service-section .section-top .section-top-text .gradient-bg {
    height: 80px;
    width: 40%;
  }
  .service-section .section-top .section-top-text h3 {
    width: 60%;
  }
  .service-section .img-left .section-top .section-top-text h3 {
    padding-left: 20px;
  }
  .service-section .img-right .section-top .section-top-text h3 {
    padding-right: 20px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .logo img {
    width: 200px;
  }

  .header-menu ul {
    opacity: 0;
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 10;
    text-align: center;
    background: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
    transition: all 0.5s;
    top: 100%;
    padding: 20px 0;
  }
  .header-menu ul.open {
    visibility: visible;
    opacity: 1;
  }
  .header-menu ul li {
    margin-left: 0;
    margin-bottom: 15px;
  }

  .burger {
    display: block;
  }

  .home-banner {
    min-height: 400px;
  }
  .home-banner h1 {
    margin-bottom: 30px;
  }

  .services-banner {
    min-height: 400px;
  }
  .services-banner .services-img {
    display: none;
  }

  .services {
    padding: 40px 0;
  }

  .text-with-img .section-img {
    position: static;
    width: 100%;
    height: 300px;
  }
  .text-with-img .section-text {
    padding-top: 0;
    max-width: 100% !important;
  }
  .text-with-img .section-text .section-text-content {
    padding: 30px 20px;
  }

  .quality {
    padding-bottom: 50px;
  }

  .about-company {
    padding: 50px 0;
  }

  .contact-social {
    padding: 40px 20px;
  }
  .contact-social ul {
    flex-wrap: wrap;
    width: 230px;
    margin: 0 auto;
  }
  .contact-social ul li {
    width: 100%;
    margin: 0;
    padding-bottom: 25px;
  }
  .contact-social ul li img {
    margin-right: 20px;
    width: 24px;
  }

  .contact-form {
    padding: 40px 0;
  }

  .form-inputs {
    flex-wrap: wrap;
  }
  .form-inputs > div {
    width: 100%;
  }
  .form-inputs > div:first-child {
    padding-bottom: 25px;
  }

  .other-services {
    padding: 40px 0;
  }
  .other-services h3 {
    padding-bottom: 25px;
  }
  .other-services .content {
    flex-wrap: wrap;
    max-width: 320px;
  }
  .other-services .content ul {
    width: 100%;
  }

  .top-footer .top-footer-cols > div {
    width: 100%;
    text-align: center;
  }
  .top-footer .top-footer-cols > div:not(:last-child) {
    padding-right: 0;
  }
  .top-footer .top-footer-cols > div:last-child {
    padding-bottom: 40px;
  }
}