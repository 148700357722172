html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

article, aside, details, figcaption, figure, dialog,
footer, header, hgroup, menu, nav, section, main {
    display: block;
}

ul, li {
    list-style: none;
}

blockquote, q {
    quotes: none;

    &:before, &:after {
        content: '';
        content: none;
    }
}

a {
    text-decoration: none;
    font-size: 100%;
    color: inherit;
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
