

.home-banner {
    min-height: 840px;

    .content {
        max-width: 840px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        text-align: center;
    }

    h1 {
        margin-bottom: 150px;
    }

    p {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        font-size: 24px;
        color: #492392;
    }
}

.services {
    background-image: $gradient-primary;
    padding: 100px 0;
    position: relative;

    .service-btn {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    .service-card {
        display: block;
        cursor: pointer;
        width: 90%;
        height: 0;
        padding-bottom: 66.67%;
        position: relative;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;

        &:hover {
            transform: scale(1.06);
            z-index: 1;
            box-shadow: 0px 0px 74px 2px rgba(0, 0, 0, 0.2);
        }

        img {
            position: absolute;
            border-radius: 6px;
        }

        p {
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            padding: 0 10px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}

.about-company {
    padding: 180px 0;
    background: url("../../../img/zenon-bg.png") no-repeat right top;
    background-size: 80%;
}

.text-with-img {
    margin-top: 30px;
    position: relative;

    .section-img {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 80%;
        height: 545px;
        background: no-repeat center;
        background-size: cover;
    }
    .section-text {
        padding-top: 100px;
        max-width: 600px;
        width: 100%;


        .section-text-content {
            padding: 70px 50px;
            background-color: #f1fbff;
        }
    }

    &.img-right {
        .section-text {
            margin-right: auto;
        }
        .section-img {
            right: 0;
        }
    }

    &.img-left {
        .section-text{
            margin-left: auto;
        }
        .section-img  {
            left: 0;
        }
    }
}

.quality {
    padding-bottom: 100px;
}

.partners {
    p {
        width: 600px;
        margin: 0 auto;
    }
}

.partners-logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
        margin: 0 25px 15px;
    }
}

.contact-title {
    background-color: #eae9f6;
    padding: 50px 0;

    p {
        width: 600px;
        margin: 0 auto;
    }
}

.contact-social {
    background-image: $gradient-primary;
    padding: 40px;

    ul {
        display: flex;
        justify-content: center;
        color: #fff;

        li {
            margin: 0 50px;
            display: flex;
            align-items: center;

            img {
                margin-right: 20px;
            }
        }
    }
}

.contact-form {
    background-image: $gradient-primary;
    padding: 80px 0;

    form {
        max-width: 700px;
        padding: 0 20px;
        margin: 0 auto;
    }
}
.form-inputs {
    display: flex;
    justify-content: space-between;
    > div {
        width: 48%;
    }
}
